<template>
  <div>
    <vue-good-table
      class="pt-2"
      :columns="columns"
      :rows="params"
    >

      <template
        slot="table-row"
        slot-scope="props"
      >
        <!-- Column: Quantity -->
        <span v-if="props.column.field === 'field'">
          <b-link
            v-clipboard:copy="props.formattedRow[props.column.field]"
            v-clipboard:success="onCopy"
            v-clipboard:error="onError"
          >
            {{ props.formattedRow[props.column.field] }}
          </b-link>

        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

    </vue-good-table>

  </div>

</template>

<script>
import { BLink } from 'bootstrap-vue';
import { VueGoodTable } from 'vue-good-table';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import { useUtils as useI18nUtils } from '@core/libs/i18n';

export default {
  components: {
    BLink,
    VueGoodTable,
  },
  props: {

    params: {
      type: Array,
      default: () => [],
    },
    error: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      data: this.params,
    };
  },

  watch: {
    params(old) {
      this.data = old;
    },
  },

  methods: {
    onCopy() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Đã sao chép',
          icon: 'BellIcon',
        },
      });
    },
    onError() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Không thể sao chép',
          icon: 'BellIcon',
        },
      });
    },
  },

  setup() {
    const { t } = useI18nUtils();
    // Table Handlers
    const columns = [
      {
        label: t('Giá trị'),
        field: 'field',
        sortable: false,
      },
      {
        label: t('Diễn giải'),
        field: 'description',
        sortable: false,
      },
    ];

    return {
      columns,
      t,
    };
  },
};
</script>

<style lang="scss">
.vs__dropdown-menu {
  z-index: 1041 !important;
}
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";

.contract-list-container {
  .vgt-table {
      thead {
        tr {
          th {
            min-width: 120px !important;

            &:first-child {
              min-width: 50px !important;
            }

            &:nth-child(3) {
               min-width: 180px !important;
            }
            &:nth-child(4) {
               min-width: 200px !important;
            }
            &:nth-child(10) {
               min-width: 200px !important;
            }
          }
        }
      }
    }
}
</style>
